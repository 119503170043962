import { CloseOutlined, RedoOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import IconViewImages from "../../../../images/file_manager/IconViewImages.svg";
import IconStatusNotQualifiedFooter from "../../../../images/file_manager/IconStatusNotQualifiedFooter.svg";
import IconStatusImgNotGoodFooter from "../../../../images/file_manager/IconStatusImgNotGoodFooter.svg";
import iconProcessingDetail from "../../../../images/file_manager/iconProcessingDetail.svg";
import DetailIcon from "../../../../images/ViewDetail.svg"
import LoadingIcon from "../../../../images/iconLoading.svg";
import { Pagination, FreeMode } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";

import IconStatusQualifiedFooter from "../../../../images/file_manager/IconStatusQualifiedFooter.svg";
import ModalViewImage from "../ModalViewImage";
import dayjs from "dayjs";
import { localhost } from "../../../../server";
import language from "../../../../language.json";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { authAxios } from "../../../../api/axiosClient";

const ModalViewDetail = ({
  open,
  setOpenModalDetail,
  dataDetail,
  chooseLanguage,
  fetchListData,
  fieldFilter,
}) => {
  const [openViewImage, setOpenViewImage] = useState(false);
  const [dataNotQualified, setDataNotQualified] = useState([]);
  const [dataQA, setDataQA] = useState("");
  const [listDataMDG, setListDataMDG] = useState([])
  const [showCheckVerified, setShowCheckVerified] = useState(
    dataDetail.pack_status === "1" && dataDetail.cus_status === "-1"
  );
  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));

  const handleCancel = () => {
    setOpenModalDetail(false)
  }

  const showListImageDetail = () => {
    setOpenViewImage(true);
  };

  const iconCircle = () => {
    return (
      <div
        style={{
          width: 8,
          height: 8,
          background: "red",
          borderRadius: "100%",
          marginInlineEnd: 8,
        }}
      ></div>
    );
  };

  const UpdateCusStatus = async (value, type) => {
    if (type !== value.cus_status) {
      const FormData = require("form-data");
      let data = new FormData();
      data.append("id_user", inforUser.user_id);
      data.append("user_role", inforUser.user_role);
      data.append("tb_package", value.tb_package);
      data.append("vl_status", type);
      data.append("pack_id", value.pack_id);
      await authAxios()
        .post(`${localhost}/update_cus_status`, data)
        .then((res) => {
          fetchListData(fieldFilter);
          setShowCheckVerified(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const showIconStatus = (src) => {
    return (
      <img
        src={src}
        alt=""
        style={{ marginInlineEnd: 8 }}
      ></img>
    )
  }

  const showIconCheckStatus = () => {
    if (parseInt(dataDetail.is_multi) === 1) {
      if (parseInt(dataDetail.is_checksheet) === 0 || parseInt(dataDetail.is_checksheet) === 3) {
        if (Number(dataDetail.pack_status) < 1) {
          return showIconStatus(iconProcessingDetail)
        }
        else if (Number(dataDetail.pack_status) >= 1) {
          if (Number(dataDetail.check_status) === 1) {
            return showIconStatus(IconStatusQualifiedFooter)
          }
          else if (Number(dataDetail.check_status) === 2) {
            return showIconStatus(IconStatusNotQualifiedFooter)
          }
          else if (Number(dataDetail.check_status) === 3) {
            return showIconStatus(IconStatusImgNotGoodFooter)
          } else if (Number(dataDetail.total_qa) > 0 || Number(dataDetail.total_warning) > 0) {
            return showIconStatus(IconStatusImgNotGoodFooter)
          } else if (Number(dataDetail.total_notqualified) > 0) {
            return showIconStatus(IconStatusNotQualifiedFooter)
          } else {
            return showIconStatus(IconStatusQualifiedFooter)
          }
        }
      }
      else if (parseInt(dataDetail.is_checksheet) === 1) {
        if (Number(dataDetail.pack_status) >= 1) {
          if (Number(dataDetail.total_qa) > 0 || Number(dataDetail.total_warning) > 0) {
            return showIconStatus(IconStatusImgNotGoodFooter)
          } else if (Number(dataDetail.total_notqualified) > 0) {
            return showIconStatus(IconStatusNotQualifiedFooter)
          } else {
            return showIconStatus(IconStatusQualifiedFooter)
          }
        } else {
          return showIconStatus(iconProcessingDetail)
        }
      }
      // LK
    } else if (parseInt(dataDetail.is_checksheet) === 0) {
      if (dataDetail.lst_data_multi.length === 0) {
        return showIconStatus(iconProcessingDetail)
      }
      else if (parseInt(dataDetail.total_qa) > 0 || Number(dataDetail.total_warning) > 0) {
        return showIconStatus(IconStatusImgNotGoodFooter)
      } else if (parseInt(dataDetail.total_notqualified) > 0) {
        return showIconStatus(IconStatusNotQualifiedFooter)
      } else {
        return showIconStatus(IconStatusQualifiedFooter)
      }
    } else if (dataDetail.check_status.toString() === "2") {
      return showIconStatus(IconStatusNotQualifiedFooter)
    } else if (dataDetail.check_status.toString() === "1") {

      return showIconStatus(IconStatusQualifiedFooter)

    } else if (dataDetail.check_status.toString() === "0") {
      return showIconStatus(iconProcessingDetail)
    } else {
      return showIconStatus(IconStatusImgNotGoodFooter)
    }
  };

  useEffect(() => {
    if (dataDetail !== undefined) {
      const newList = dataDetail.lst_data_multi.filter(item => item.not_qualified.length > 0 || item.qa_content !== "")
      if (dataDetail.lst_data.length > 0) {
        setDataNotQualified(dataDetail.lst_data[0].not_qualified);
        setDataQA(dataDetail.lst_data[0].qa_content);
      }
      setListDataMDG(newList)
    }
  }, [dataDetail]);

  return (
    <>
      <Modal className="container-modal-view-detail" open={open} footer={false}>
        <div style={{ height: "auto" }}>
          <Row className="container-header-view-detail">
            <Col span={4}>
              <Button className="btn-view-detail" onClick={showListImageDetail}>
                <img src={IconViewImages} alt=""></img>
              </Button>
            </Col>
            <Col span={16} className="title-view-detail">
              <span style={{ display: "flex" }}>
                {showIconCheckStatus()}
                {dataDetail.vl_mfg_no.toUpperCase()}
              </span>
            </Col>
            <Col span={4} style={{ textAlign: "-webkit-right" }}>
              <Button className="btn-view-detail" onClick={handleCancel}>
                <CloseOutlined />
              </Button>
            </Col>
          </Row>
          <div style={{ width: "100%" }}>
            <Row className="container-detail-information">
              <span>
                {language[chooseLanguage].pump_type}:{" "}
                <span className="text-detail">{dataDetail.pumb_name}</span>
              </span>
              <span>
                {language[chooseLanguage].model_name}:{" "}
                <span className="text-detail">{dataDetail.vl_model_name}</span>
              </span>
              <span>
                {language[chooseLanguage].location}:{" "}
                <span className="text-detail">{dataDetail.pump_location}</span>
              </span>
              <span>
                {language[chooseLanguage].upload_date}:{" "}
                <span className="text-detail">
                  {dayjs(dataDetail.upload_date).format("DD-MM-YYYY HH:mm:ss")}
                </span>
              </span>

              {parseInt(dataDetail.pumb_id) !== 1 && <span>
                {language[chooseLanguage].total}:{" "}
                <span className="text-detail">
                  {dataDetail.total_pump + " / " + dataDetail.total_files}
                </span>
              </span>}

              {dataDetail.vl_scan_no !== "" &&
                <span>
                  {language[chooseLanguage].vl_scan_no}:{" "}
                  <span className="text-detail">
                    {dataDetail.vl_scan_no}
                  </span>
                </span>
              }
            </Row>

            {parseInt(dataDetail.is_checksheet) === 1 ?
              <StatusFieldLK
                dataDetail={dataDetail}
                chooseLanguage={chooseLanguage}
                dataNotQualified={dataNotQualified}
                showCheckVerified={showCheckVerified}
                iconCircle={iconCircle}
                dataQA={dataQA}
              />
              :
              <StatusFieldMDG
                dataDetail={dataDetail}
                chooseLanguage={chooseLanguage}
                listDataMDG={listDataMDG}
                inforUser={inforUser}
              />
            }


            {showCheckVerified ? (
              <Row className="container-footer-view-detail">
                <Col span={24}>
                  <Button
                    size="large"
                    onClick={() => UpdateCusStatus(dataDetail, "0")}
                  >
                    {language[chooseLanguage].not_ok}
                  </Button>
                </Col>
                <Col span={24}>
                  <Button
                    size="large"
                    onClick={() => UpdateCusStatus(dataDetail, "1")}
                    style={{ background: "#0C4DA2", color: "#fff" }}
                  >
                    {language[chooseLanguage].ok}
                  </Button>
                </Col>
              </Row>
            ) : null}
          </div>
        </div>
      </Modal>

      {openViewImage === true ? (
        <ModalViewImage
          open={openViewImage}
          setIsOpenDetail={setOpenViewImage}
          dataDetail={dataDetail}
        />
      ) : null}
    </>
  );
};

const StatusFieldLK = ({
  dataDetail,
  chooseLanguage,
  dataNotQualified,
  showCheckVerified,
  iconCircle,
  dataQA
}) => {

  const returnDataQA = () => {
    if (dataQA.includes("002")) {
      return language[chooseLanguage].code_002
    } else if (dataQA.includes("003")) {
      return language[chooseLanguage].code_003
    } else {
      return dataQA
    }
  }

  const showDataLK = (data) => {
    if (chooseLanguage.toLowerCase() === "japanese") {
      return data.qa_jp
    } else if (chooseLanguage.toLowerCase() === "english") {
      return data.qa_en
    } else {
      return data.qa_vn
    }
  }

  return (
    <>
      {parseInt(dataDetail.check_status) === 2 ? (
        <div className="container-error-field">
          {chooseLanguage !== "vietnamese" ? (
            <p style={{ display: "flex" }}>
              {language[chooseLanguage].there_are}{" "}
              <p style={{ color: "#F51313" }}>
                &nbsp;" {dataNotQualified.length}{" "}
                {language[chooseLanguage].not_qualified} "&nbsp;
              </p>{" "}
              {language[chooseLanguage].fields}
            </p>
          ) : (
            <p style={{ display: "flex" }}>
              {language[chooseLanguage].there_are}{" "}
              <p style={{ color: "#F51313" }}>
                &nbsp;" {dataNotQualified.length}{" "}
                {language[chooseLanguage].fields}{" "}
                {language[chooseLanguage].not_qualified} "&nbsp;
              </p>
            </p>
          )}
          <div
            style={{
              height: showCheckVerified ? "35svh" : "50svh",
              overflow: "auto",
            }}
          >
            {dataNotQualified.map((item) => (
              <span key={item} style={{ display: "grid", alignItems: "center" }}>
                <span style={{ display: "flex", alignItems: "center" }}>
                  {iconCircle()}
                  {item.field}:{" "}
                </span>
                <span className="text-detail" style={{ paddingLeft: '6%' }}>
                  {showDataLK(item)}
                </span>
              </span>
            ))}
          </div>
        </div>
      ) : parseInt(dataDetail.check_status) === 3 ? (
        <div className="container-qa-field">
          <p>Note: {returnDataQA()}</p>
        </div>
      ) : null}
    </>
  )
}

const StatusFieldMDG = ({
  dataDetail,
  chooseLanguage,
  listDataMDG,
  inforUser
}) => {
  const [isOpenModalImage, setIsOpenModalImage] = useState(false)
  const [items, setItems] = useState([])
  const [dataDetailPackage, setDataDetailPackage] = useState()

  const labelCollapse = (text, data) => {
    let colorLabel = ''
    if (Number(data.is_warning) === 1) {
      colorLabel = "rgb(14, 102, 231)"
    } else if (data.qa_content !== "") {
      colorLabel = "#BB890A"
    } else {
      colorLabel = "#F60505"
    }

    return (
      <span style={{ columnGap: 8, color: colorLabel, textTransform: "uppercase" }}><img alt=''></img>{text}</span>
    )
  }
  const showImage = (data) => {
    setDataDetailPackage(data)
    setIsOpenModalImage(true)
  }
  const genExtra = (data) => {
    return (
      <button
        style={{ border: 0, background: "none"}}
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          showImage(data)
        }}
      >
        <img
          style={{ opacity: "0.7" }}
          src={DetailIcon} alt=''
          loading="lazy"
        ></img>
      </button>

    )
  };

  useEffect(() => {
    if (listDataMDG.length > 0) {
      listDataMDG.sort((a, b) => Number(b.is_warning) - Number(a.is_warning))
      let newItem = []
      listDataMDG.map((item, index) =>
        newItem.push({
          key: index + 1,
          label: labelCollapse(item.mfg_no, item),
          children: <BoxPackage data={item} chooseLanguage={chooseLanguage} />,
          extra: genExtra(item),
        })
      )
      setItems(newItem)
    }
  }, [listDataMDG]);
  return (
    <>
      <Row className="container-detail-information">
        <span><span style={{ color: "#0fa958" }}>{language[chooseLanguage].qualified} </span> {language[chooseLanguage].checksheet}:  <span className="text-detail">{dataDetail.total_qualified}</span></span>
        <span><span style={{ color: "#f60505" }}>{language[chooseLanguage].not_qualified}</span> {language[chooseLanguage].checksheet}:   <span className="text-detail">{dataDetail.total_notqualified}</span></span>
        <span><span style={{ color: "#c7a242" }}>{language[chooseLanguage].images_not_good} </span>{language[chooseLanguage].checksheet}: <span className="text-detail">{dataDetail.total_qa}</span></span>
        <span><span style={{ color: "rgb(14 102 231)", fontWeight: "bold" }}>{language[chooseLanguage].warning}: </span><span className="text-detail">  {dataDetail.total_warning}   </span></span>
      </Row>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Collapse className='collapse-desktop' style={{ maxHeight: "28vh", marginTop: "2%" }} expandIconPosition="start" size='small' items={items} defaultActiveKey={[]} />
      </div>
      {isOpenModalImage === true ?
        <ModalViewImageMDG
          open={isOpenModalImage}
          setIsOpenModalImage={setIsOpenModalImage}
          dataDetailPackage={dataDetailPackage}
          inforUser={inforUser}
        />
        : null}
    </>
  )
}

const BoxPackage = ({ data, chooseLanguage }) => {
  const iconCircle = () => {
    return (
      <div
        style={{
          width: 8,
          height: 8,
          background: "red",
          borderRadius: "100%",
          marginInlineEnd: 8,
        }}
      ></div>
    );
  };

  const returnDataQA = () => {
    if (data.qa_content.includes("002")) {
      return <span style={{ fontSize: 12 }}>{language[chooseLanguage].code_002}</span>
    } else if (data.qa_content.includes("003")) {
      return <span style={{ fontSize: 12 }}>{language[chooseLanguage].code_003}</span>
    } else {
      return <span style={{ fontSize: 12 }}>{data.qa_content}</span>
    }
  }

  const showDataMDG = (data) => {
    if (chooseLanguage.toLowerCase() === "japanese") {
      return data.qa_jp
    } else if (chooseLanguage.toLowerCase() === "english") {
      return data.qa_en
    } else {
      return data.qa_vn
    }
  }


  return (
    <div
      style={{
        maxHeight: "20svh",
        overflow: "auto",
      }}
      className="content-collapse"
    >
      {returnDataQA()}

      {data.not_qualified.map((item) => (
        <span key={item.field} style={{ display: "grid", alignItems: "center", fontSize: 12 }}>

          <span style={{ color: "red", display: "flex", alignItems: "center" }}> {iconCircle()}{item.field}:{" "} &nbsp;</span>
          <span className="text-detail" style={{ paddingLeft: "6%" }}>
            {showDataMDG(item)}
          </span>
        </span>
      ))}
    </div>
  )
}

const ModalViewImageMDG = ({ open, setIsOpenModalImage, dataDetailPackage, inforUser }) => {
  const [mainImageURL, setMainImageURL] = useState();
  const [thumbnailURL, setThumbnailURL] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [indexImage, setIndexImage] = useState(0);
  const handleCancel = () => {
    setIsOpenModalImage(false);
  };

  const convertToImage = (value) => {
    let arrData = [];
    for (let i = 0; i < value.lst_thum_base64.length; i++) {
      arrData.push(`data:image/jpeg;base64,${value.lst_thum_base64[i]}`);
    }
    setThumbnailURL(arrData);
    setMainImageURL(`data:image/jpeg;base64,${value.img_base64}`);
    setLoadingImage(false);
  };

  const fetchListImage = async (index) => {
    setLoadingImage(true);
    await authAxios()
      .post(
        `${localhost}/file_details`,
        {
          pack_file_path: dataDetailPackage.path_files[index],
          pack_list_thumbnail_path: dataDetailPackage.path_thumbs,
          user_role: inforUser.user_role
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        convertToImage(res.data);
      })
      .catch((err) => {
        setLoadingImage(false);
      });
  };

  useEffect(() => {
    if (open === true) {

      fetchListImage(indexImage);
    }
  }, [open]);

  useEffect(() => {
    setRotate(0);
  }, [indexImage]);

  const changeMainImage = (index) => {
    setIndexImage(index);
    if (index !== indexImage) {
      fetchListImage(index, dataDetailPackage);
    }
  };

  const mobileScreen = window.screen.availWidth <= 900;

  const [rotate, setRotate] = useState(0);

  return (
    <Modal
      className='container-modal-view-detail detail-view-image'
      open={open}
      width={mobileScreen ? "96%" : "50%"}
      onCancel={handleCancel}
      footer={false}
      style={{ top: 10, maxHeight: "75vh" }}
    >
      <Row>
        <Row style={{ width: "100%", paddingTop: 6 }}>
          <Col
            span={20}
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: "2ch",
              paddingTop: 4,
            }}
          >
            <Button
              style={{ padding: 0, height: 28, width: 50 }}
              icon={<UndoOutlined style={{ fontSize: 18 }} />}
              onClick={() => setRotate(rotate - 90)}
            ></Button>
            <Button
              style={{ padding: 0, height: 28, width: 50 }}
              icon={<RedoOutlined style={{ fontSize: 18 }} />}
              onClick={() => setRotate(rotate + 90)}
            ></Button>
          </Col>
          <Col span={4} style={{ textAlign: "-webkit-right" }}>
            <Button className='btn-view-detail' style={{ height: 30 }} onClick={handleCancel}>
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
        <Col span={24}>
          <div
            style={{ position: "relative", paddingTop: "2%" }}
            className="size-image"
          >
            {loadingImage === false ? (
              <>
                <TransformWrapper initialScale={1}>
                  {({ zoomIn, zoomOut, resetTransform }) => (
                    <>
                      <TransformComponent
                        contentStyle={{
                          cursor: "zoom-in",
                          width: "100%",
                          display: "flex",
                          padding: "1% 0% 2%",
                          height: "72svh",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={mainImageURL}
                          style={{ transform: `rotate(${rotate}deg)` }}
                          alt="Hình ảnh"
                          className="img-detail"
                        />
                      </TransformComponent>
                    </>
                  )}
                </TransformWrapper>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  padding: "1% 1% 2%",
                  height: "72svh",
                  justifyContent: "center",
                }}
              >
                <img
                  style={{ width: "7%" }}
                  src={LoadingIcon}
                  className="load-image-desktop"
                  alt=""
                ></img>
              </div>
            )}
          </div>

          <div className="thumbnail-class">
            <Swiper
              slidesPerView={
                mobileScreen
                  ? window.screen.width * 0.01 * 0.96
                  : window.screen.width * 0.01 * 0.4
              }
              pagination={{
                clickable: true,
              }}
              centerInsufficientSlides={true}
              modules={[FreeMode, Pagination]}
              style={{ width: "90%" }}
              className="mySwiper"
            >
              {thumbnailURL.map((item, index) => (
                <SwiperSlide
                  style={{
                    height: "12vh",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <img
                    onClick={() => changeMainImage(index)}
                    style={{
                      border: index === indexImage ? "2px solid red" : null,
                    }}
                    src={item}
                    alt=""
                  ></img>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalViewDetail;
