// export const localhost = "http://42.113.15.62:5001";
// export const localhost = "http://192.168.10.22:5009";
// export const localhost = "https://iwaki.rainscales.com/api"; //5000 // Build xong nhớ copy lại src
// export const localhost = 'https://truongtx.dominatech.xyz/api'
export const localhost = 'https://iwaki-test.dominatech.xyz/api' //5001
// export const localhost = 'https://iwaki-phase1.dominatech.xyz/api' // 5010
// export const localhost = "http://192.168.20.162:5009";
// export const localhost = 'https://scan-test.dominatech.xyz/api' //5009
// export const localhost = 'https://tailnt.dominatech.xyz/api'
// export const localhost = "http://192.168.20.190:8888";
// export const localhost = "https://longlh.dominatech.xyz/api";
