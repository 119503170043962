import React from "react";
import ModalSelectPumpType from "./ModalSelectPumpType";

const SelectPumpType = ({
  showModalSelectPumpType,
  setShowModalSelectPumpType,
  handleChangeSelectOptions,
  fileLanguage,
  chooseLanguage,
  items2,
  items,
  iconArrowDropDown,
  checkMachineBeforeChangeCamera,
}) => {
  const handleCancelModalSelectPumpType = () => {
    setShowModalSelectPumpType(false);
  };

  return (
    <ModalSelectPumpType
      showModalSelectPumpType={showModalSelectPumpType}
      handleCancelModalSelectPumpType={handleCancelModalSelectPumpType}
      handleChangeSelectOptions={handleChangeSelectOptions}
      fileLanguage={fileLanguage}
      chooseLanguage={chooseLanguage}
      items={items}
      items2={items2}
      iconArrowDropDown={iconArrowDropDown}
      checkMachineBeforeChangeCamera={checkMachineBeforeChangeCamera}
    />
  );
};

export default SelectPumpType;
