import { Table } from "antd";
import React from "react";
import PropTypes from "prop-types";

const TableDashboard = ({
  columns,
  checkClickSearch,
  valueSearch,
  listPackageAll,
  screenHeight,
}) => {
  return (
    <Table
      columns={columns}
      dataSource={checkClickSearch === true ? valueSearch : listPackageAll}
      scroll={{
        y: screenHeight - 630,
      }}
      size="small"
      className="management-dashboard-table"
    />
  );
};

TableDashboard.propTypes = {
  columns: PropTypes.any,
  checkClickSearch: PropTypes.any,
  valueSearch: PropTypes.array,
  listPackageAll: PropTypes.array,
  screenHeight: PropTypes.number,
};

export default TableDashboard;
