import React from "react";
import "../Layout.css";
import PropTypes from "prop-types";

const CustomLayoutFC = ({ children }) => {
  return (
    <div className="container-fluid" style={{ maxWidth: "100%" }}>
      {children}
    </div>
  );
};

export const CustomNoLayout = ({ isLogged, children }) => {
  // Test
  return isLogged ? (
    <CustomLayoutFC children={children} />
  ) : (
    <CustomLayoutFC children={children} />
  );
};

CustomLayoutFC.propTypes = {
  children: PropTypes.any,
};

CustomNoLayout.propTypes = {
  children: PropTypes.any,
  isLogged: PropTypes.bool,
};
