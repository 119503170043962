import React from "react";
import Arrow from "../../images/ArrowIconLogin.svg";
import "./Login.css";
import { Redirect } from "react-router-dom";

import { Button, Checkbox, Form, Input } from "antd";
import Cookies from "universal-cookie";
import { authLogin } from "../../app/Actions/auth";
import { connect } from "react-redux";
import logoIwaki from "../../images/LogoIwaki.svg";
import userIcon from "../../images/userIcon.svg";
import passwordIcon from "../../images/passwordIcon.svg";
import alertCircle from "../../images/alertCircle.svg";

const cookies = new Cookies();

function setCookieRemember(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();

  // Mã hóa dữ liệu trước khi lưu vào cookie
  const encodedValue = window.btoa(cvalue); // Mã hóa dữ liệu bằng Base64
  document.cookie = cname + "=" + encodedValue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const encodedValue = c.substring(name.length, c.length);
      // Giải mã dữ liệu khi lấy từ cookie
      return window.atob(encodedValue); // Giải mã dữ liệu từ Base64
    }
  }
  return "";
}
class Login extends React.Component {
  state = {
    username: "",
    password: "",
    loadings: false,
    modalVisibleRestPass: false,
    isFocusedUser: false,
    isFocusedPass: false,
    checked: "",
    openModalContact: false,
    openForgetPassword: false,
    openCreateNewPassword: false,
    textAlert: "",
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  handleModalForgotPass = () => {
    this.setState({
      modalVisibleRestPass: true,
    });
  };

  handleModalCancel = () => {
    this.setState({ modalVisibleRestPass: false });
  };

  handleFocus = () => {
    this.setState({ isFocusedUser: true });
  };

  handleBlur = () => {
    this.setState({ isFocusedUser: false });
  };

  handleFocus1 = () => {
    this.setState({ isFocusedPass: true });
  };

  handleBlur1 = () => {
    this.setState({ isFocusedPass: false });
  };

  onChangeRemember = (e) => {
    localStorage.setItem("remember_me", e.target.checked);
    this.setState({
      checked: e.target.checked,
    });
  };

  handleOpenModalContact = () => {
    this.setState({ openModalContact: true });
  };

  handleCloseModalContact = () => {
    this.setState({ openModalContact: false });
  };

  handleLoading = (value) => {
    this.setState({ loadings: value });
  };

  handleOpenForgerPassword = () => {
    window.location = "/forgot-password";
  };

  constructor(props) {
    super(props);
    this.state = {
      username: getCookie("i_u") || "", // Lấy giá trị từ localStorage
      password: getCookie("i_p") || "",
      checked: localStorage.getItem("remember_me") || "",
      textAlert:
        "Your password is not strong enough. Use at least 8 characters",
      // remember_me: localStorage.getItem("remember_me") || "",
      // Các trạng thái khác của component
    };
  }

  componentDidMount() {
    // const tokem = cookies.get("token_iwaki")
    // if (tokem) {
    //   window.location = "/last_check";
    // }

    // console.log("Chay")
    // sessionStorage.clear()
    // cookies.remove("refresh_iwaki");
    // cookies.remove("token_iwaki");
  }

  render() {
    const { token } = this.props;
    const { username, password } = this.state;
    const { error } = this.props;

    if (token) {
      // localStorage.setItem("currentSelectedKeys", JSON.stringify(['2']))
      return <Redirect to="/login" />;
    }

    const onFinish = (values) => {
      // this.setState({ loadings: true });
      this.props.login(values.username, values.password, (e) =>
        this.handleLoading(e)
      );
      const checkRemember =
        localStorage.getItem("remember_me") !== undefined &&
        localStorage.getItem("remember_me") === "true";
      // const checkRemember = true;
      if (checkRemember === true) {
        setCookieRemember("i_u", values.username, 1);
        setCookieRemember("i_p", values.password, 1);
      } else {
        cookies.remove("i_u");
        cookies.remove("i_p");
        localStorage.setItem("remember_me", false);
      }
      // this.setState({ loadings: false });
    };

    const defaultCheckValue = () => {
      if (
        localStorage.getItem("remember_me") !== undefined &&
        localStorage.getItem("remember_me") === "true"
      ) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <div className="Login">
        <div className="logoIwakiLogin">
          <img
            className="logoIwakiSVG"
            style={{ borderRadius: "0" }}
            src={logoIwaki}
            alt=""
          />
          <p className="spanSignInTitle">Sign in to your account</p>
        </div>
        <div className="loginForm">
          <Form
            className="formLogin"
            initialValues={{
              username: username || "",
              password: password || "",
            }}
            // form={form}
            name="horizontal_login"
            layout="inline"
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input
                className="textInputLogin"
                prefix={<img src={userIcon} alt="" />}
                placeholder="Username"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                className="textInputLogin"
                prefix={<img src={passwordIcon} alt="" />}
                // type="password"
                placeholder="Password"
              />
            </Form.Item>
            {error !== null && error !== undefined ? (
              <div className="alertLogin">
                <img className="iconAlertLogin" src={alertCircle} alt="" />
                <span className="textAlertLogin">{error}</span>
              </div>
            ) : null}
            <div className="FooterLoginForm">
              <div className="FooterItemLogin">
                <div className="rememberForgot">
                  <Checkbox
                    defaultChecked={defaultCheckValue}
                    onChange={this.onChangeRemember}
                    className="cbRemeber"
                  >
                    Remember me
                  </Checkbox>
                  <a
                    style={{ border: "none" }}
                    className="linkForgot"
                    href="/forgot-password"
                  >
                    <span className="spanForgot">Forgot your password ?</span>
                  </a>
                </div>
                <div className="signIn">
                  <span className="spanSignIn">Sign in</span>
                  <Button className="btnSignIn" htmlType="submit">
                    <img className="iconArrow" src={Arrow} alt="" />
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, loading) =>
      dispatch(authLogin(username, password, loading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
