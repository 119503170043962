import * as React from "react";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./Layout.css";
import HeaderWeb from "./Header/HeaderWeb";

const CustomLayoutFC = ({ children }) => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ display: "flex", height: "100vh" }}>
          <main className="content1">
            <HeaderWeb />
            <div className="container-fluid" style={{ maxWidth: "100%" }}>
              {children}
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const CustomLayout = ({ isLogged, children }) => {

  // Test
  return isLogged ? (
    <CustomLayoutFC children={children} />
  ) : (
    <CustomLayoutFC children={children} />
  );
};
