import { Button, Input } from "antd";
import { useState } from "react";

const ConvertApp = () => {
  const [currentText, setCurrentText] = useState("text1");
  const [valueText1, setValueText1] = useState(undefined);
  const [valueText2, setValueText2] = useState(undefined);

  const handleChange = () => {
    setCurrentText(currentText === "text1" ? "text2" : "text1");
  };

  return (
    <div>
      <Input
        value={valueText1}
        onChange={(e) => setValueText1(e.target.value)}
      />
      <Input
        value={valueText2}
        onChange={(e) => setValueText2(e.target.value)}
      />
      <Button onClick={handleChange}>Click</Button>
      {valueText1 !== undefined && valueText2 !== undefined ? (
        <>
          <HighlightDifference
            text1={valueText1}
            text2={valueText2}
            currentText={currentText}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ConvertApp;

function HighlightDifference({ text1, text2, currentText }) {
  const result = [];

  switch (currentText) {
    case "text1":
      if (text1.length !== text2.length) {
        for (let i = 0; i < text1.length; i++) {
          if (text1[i] !== text2[i]) {
            result.push(<span style={{ color: "red" }}>{text1[i]}</span>);
          } else {
            result.push(text1[i]);
          }
        }
      } else {
        // Nếu độ dài của text1 bằng độ dài của text2, so sánh từng ký tự
        for (let i = 0; i < text1.length; i++) {
          if (text1[i] !== text2[i]) {
            result.push(<span style={{ color: "red" }}>{text1[i]}</span>);
          } else {
            result.push(text1[i]);
          }
        }
      }
      return <div>{result}</div>;
    case "text2":
      if (text1.length !== text2.length) {
        for (let i = 0; i < text2.length; i++) {
          if (text1[i] !== text2[i]) {
            result.push(<span style={{ color: "red" }}>{text2[i]}</span>);
          } else {
            result.push(text2[i]);
          }
        }
      } else {
        // Nếu độ dài của text1 bằng độ dài của text2, so sánh từng ký tự
        for (let i = 0; i < text2.length; i++) {
          if (text1[i] !== text2[i]) {
            result.push(<span style={{ color: "red" }}>{text2[i]}</span>);
          } else {
            result.push(text2[i]);
          }
        }
      }
      return <div>{result}</div>;
    default:
      break;
  }
}