import { useState } from "react";
import FileManager from "./body_file_manager/FileManager";
import HeaderManager from "./HeaderManager";
import FooterFileManager from "./FooterFileManager";
import PageNotification from "./page_notification/PageNotification";

const ContainerFileManager = () => {
  const [chooseLanguage, setChooseLanguage] = useState(
    sessionStorage.getItem("choosedLanguage") !== null
      ? sessionStorage.getItem("choosedLanguage")
      : "japanese"
  );
  const [checkNoti, setCheckNoti] = useState(false);
  const [listNotification, setListNotification] = useState([]);
  const [listStatus, setListStatus] = useState("");
  const [dataNotification, setDataNotification] = useState([]);
  const [countNotification, setCountNotification] = useState("");

  return (
    <div style={{ height: "100svh", position: "relative" }}>
      <HeaderManager
        checkNoti={checkNoti}
        setChooseLanguage={setChooseLanguage}
        chooseLanguage={chooseLanguage}
        setCheckNoti={setCheckNoti}
        setListNotification={setListNotification}
        setCountNotification={setCountNotification}
        countNotification={countNotification}
      />
      {checkNoti === true ? (
        <PageNotification
          chooseLanguage={chooseLanguage}
          setListStatus={setListStatus}
          setCheckNoti={setCheckNoti}
          listNotification={listNotification}
          setDataNotification={setDataNotification}
        />
      ) : (
        <>
          <FileManager
            chooseLanguage={chooseLanguage}
            setListStatus={setListStatus}
            dataNotification={dataNotification}
            setDataNotification={setDataNotification}
            setCheckNoti={setCheckNoti}
            setCountNotification={setCountNotification}
          />
          <FooterFileManager listStatus={listStatus} />
        </>
      )}
    </div>
  );
};

export default ContainerFileManager;
