import { Button, Card, Col, Row, Select } from "antd";
import "./style.scss";
import React, { useEffect, useState } from "react";
import ShowModalDetailEntry from "./modal";
import { authAxios } from "../../api/axiosClient";
import { localhost } from "../../server";
import { IconButton, Tooltip } from "@mui/material";
import { openNotificationSweetAlert } from "../../Function";
import SuccessIcon from "../../images/SuccessNotiIcon.svg";
import NoDataIcon from "../../images/file_manager/NoDataIcon.svg";
import ReactLoading from "react-loading";
import styled from "tachyons-components";
import WarningIcon from "../../images/WarningNotiIcon.svg";
import AutorenewIcon from "@mui/icons-material/Autorenew";

export const Prop = styled("h3")`
f5 f4-ns mb0 white`;

export const Article = styled("div")`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

const Entry_Classification = () => {
  const [valueBase64, setValueBase64] = useState([]);
  const [listPumb, setListPumb] = useState([]);
  const [dataDetail, setDataDetail] = useState(undefined);
  const [isShowModalDetail, setIsShowModalDetail] = useState(false);
  const [valueSecondImgBase64, setValueSecondImgBase64] = useState(undefined);
  const [dataPumb, setDataPumb] = useState(undefined);
  const [startTime, setStartTime] = useState(0);
  const [loadingBtnSubmit, setLoadingBtnSubmit] = useState(false);
  const [valueListPumb, setValueListPumb] = useState(undefined);

  const inforUser = JSON.parse(sessionStorage.getItem("info_user"));

  const fetchDataInsert = (pumbModel) => {
    const FormData = require("form-data");
    let data = new FormData();
    data.append("pumb_id", 3);
    data.append("user_pair", inforUser.user_pair);
    data.append("user_role", inforUser.user_role);
    const startTimeClick = Date.now();

    authAxios()
      .post(`${localhost}/get_entry_clf_info`, data)
      .then((res) => {
        let arrData = [];
        let arrDataReset = [];

        setStartTime(startTimeClick);
        sessionStorage.setItem("clf_id", res.data.clf_id);
        sessionStorage.setItem("clf_table", res.data.clf_table);

        res.data.lst_thum_base64.forEach((base64, index) => {
          arrData.push({
            id: index + 1,
            img_base64: `data:image/jpeg;base64,${base64}`,
            value_id: index + 1,
          });
        });

        res.data.lst_thum_base64.forEach((base64, index) => {
          arrDataReset.push({
            img_base64: `data:image/jpeg;base64,${base64}`,
            value_id: index + 1,
          });
        });

        if (res.status === 201) {
          openNotificationSweetAlert(WarningIcon, res.data.message);
        }
        setDataDetail(res.data);

        setValueBase64(arrData);
      })
      .catch((err) => {
        setValueBase64([]);
        console.log(err);
      });
  };

  const fetchListPumb = () => {
    authAxios()
      .get(`${localhost}/get_list_pump_clf`, {
        params: {
          user_role: inforUser.user_role,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setListPumb(res.data.list_pumb);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const returnPackage = (packageID, packageInfo) => {
    const FormData = require("form-data");
    let data = new FormData();
    if (packageID !== undefined && packageID !== null) {
      //  const data = JSON.parse(dataReturn)
      const clf_id = sessionStorage.getItem("clf_id");
      const clf_table = sessionStorage.getItem("clf_table");

      data.append("clf_id", clf_id);
      data.append("clf_table", clf_table);
      data.append("user_pair", parseInt(inforUser.user_pair));
      data.append("user_role", inforUser.user_role);
      authAxios()
        .post(`${localhost}/return_pack_entry_clf`, data)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const chooseModel = (value, data) => {
    if (dataDetail !== undefined) {
      returnPackage(dataDetail.clf_id, dataDetail.clf_table);
    }
    const filterPumb = listPumb.filter((e) => e.pumb_id === data.key);
    sessionStorage.setItem("OptionMachine", JSON.stringify(filterPumb));

    setDataPumb(data);
    fetchDataInsert(data);
  };

  const onFinish = () => {
    let countNumbers = 0;
    let countCheckMark = 0;
    let countP = 0;
    let countX = 0;

    valueBase64.forEach((item) => {
      const value = item.value_id;
      if (typeof value === "number") {
        countNumbers++;
      } else {
        switch (value) {
          case "☑":
            countCheckMark++;
            break;
          case "P":
            countP++;
            break;
          case "✖":
            countX++;
            break;
        }
      }
    });

    const updatedDataList = valueBase64.map((item) => ({
      ...item,
      value_id: item.value_id === "☑" ? "S" : item.value_id,
    }));

    const concatenatedValues = updatedDataList
      .map((item) => item.value_id)
      .join("‡");

    const endTime = Date.now();
    const duration = endTime - startTime;

    setLoadingBtnSubmit(true);

    authAxios()
      .post(`${localhost}/submit_entry_clf`, {
        results: concatenatedValues,
        user_Id: parseInt(inforUser.user_id),
        user_pair: parseInt(inforUser.user_pair),
        user_role: parseInt(inforUser.user_role),
        user_id: parseInt(inforUser.user_id),

        pumb_id:
          valueListPumb !== undefined ? valueListPumb.pumb_id : dataPumb.value,
        lv1_fields:
          valueListPumb !== undefined
            ? valueListPumb.lv1_fields
            : dataPumb.lv1_fields,
        lv3_fields:
          valueListPumb !== undefined
            ? valueListPumb.lv3_fields
            : dataPumb.lv3_fields,
        is_multi:
          valueListPumb !== undefined
            ? valueListPumb.is_multi
            : dataPumb.is_multi,
        pumb_model:
          valueListPumb !== undefined
            ? valueListPumb.pumb_model
            : dataPumb.pumb_model,

        msnv_e: inforUser.user_msnv,
        total_time: duration,
        total_cs: countNumbers,
        total_cs2: countCheckMark,
        total_orther: countP,
        total_delete: countX,

        prioriti: dataDetail.prioriti,
        clf_id: parseInt(dataDetail.clf_id),
        clf_table: dataDetail.clf_table,
        op_table: dataDetail.op_table,
        path_files: dataDetail.path_files,
        path_thumbs: dataDetail.path_thumbs,
        pack_id: dataDetail.pack_id,
        pack_name: dataDetail.pack_name,
        upload_user: dataDetail.upload_user,
        upload_usname: dataDetail.upload_usname,
        upload_date_jp: dataDetail.upload_date_jp,
        capture_type: dataDetail.capture_type,

        jp_time_ymd: dataDetail.jp_time_ymd,
      })
      .then((res) => {
        setLoadingBtnSubmit(false);
        setStartTime(0);
        setValueBase64([]);
        openNotificationSweetAlert(SuccessIcon, res.data.message);

        fetchDataInsert(dataPumb);
      })
      .catch((err) => {
        setLoadingBtnSubmit(false);
      });
  };

  const handleClickCard = (_index) => {
    setIsShowModalDetail(true);
    const secondImgBase64 = valueBase64[_index].img_base64;

    setValueSecondImgBase64(secondImgBase64);
  };

  function return_index_diff(arr, i) {
    // Chắc chắn giá trị của index hiện tại ko phải số

    // Bắt đầu từ giá trị của index hiện tại về trước
    let index_pre_dif0 = 0;
    for (let h = i - 1; h >= 0; h--) {
      if (["☑", "P", "N", "✖"].includes(arr[h])) {
        continue;
      }
      index_pre_dif0 = h;
      break;
    }

    // Bắt đầu từ giá trị của index hiện tại về sau
    let index_next_dif0 = 0;
    for (let k = i + 1; k < arr.length; k++) {
      if (["☑", "P", "N", "✖"].includes(arr[k])) {
        continue;
      }
      index_next_dif0 = k;
      break;
    }

    return [index_pre_dif0, index_next_dif0];
  }

  function return_check_error(return_arr, index) {
    // ----- TH giữ nguyên giá trị của index hiện tại nếu nhập sai quy tắc
    let bool_check_error = false;
    // Nếu giá trị index liền trước là Nút T và index hiện tại là phần tử cuối cùng đang nhập thì giữ nguyên
    if (return_arr[index - 1] === "☑" && index == return_arr.length - 1) {
      if (index === return_arr.length - 1) {
        if (
          return_arr[index] === "☑" &&
          ["☑", "P", "N", "✖"].includes(return_arr[index - 1])
        ) {
          bool_check_error = true;
          return bool_check_error;
        }
      } else {
        bool_check_error = true;
      }
    } else {
      // TH không phải index cuối cùng
      // Nếu giá trị index hiện tại là Nút 1 và là index đầu tiên thì giữ nguyên

      if (return_arr[index] === "☑" && index == 0) {
        bool_check_error = true;
        return bool_check_error;
      }
      // Nếu giá trị index hiện tại là Nút 1 và giá trị index liền sau là Nút 1|2|3|4 thì giữ nguyên
      if (
        return_arr[index] === "☑" &&
        ["☑", "P", "N", "✖"].includes(return_arr[index - 1])
      ) {
        bool_check_error = true;
        return bool_check_error;
      }

      // Nếu giá trị của index hiện tại là Nút 1|2|3|4 và liền sau là Nút 1 thì giữ nguyên
      if (
        ["☑", "P", "N", "✖"].includes(return_arr[index]) &&
        return_arr[index + 1] === "☑"
      ) {
        bool_check_error = true;
      }
    }
    return bool_check_error;
  }

  function updateArray(arr, index, newValue) {
    let return_arr = arr.slice();
    return_arr[index] = newValue;
    // ----- TH giữ nguyên giá trị của index hiện tại nếu nhập sai quy tắc
    let bool_check_error = return_check_error(return_arr, index);
    if (bool_check_error) {
      return arr; // Trả lại dữ liệu lần liền trước
    }

    // -----TH cho phép nhập Nút thì thay đổi giá trị các index kế sau
    let index_diff = return_index_diff(return_arr, index); // lấy ra index kế trước/sau (mà giá trị khác 0) cần tìm
    let number_previous = return_arr[index_diff[0]]; // Giá trị của index kế sau (khác Nút)
    // TH này chỉ chạy khi là Nhập cho index đầu tiên
    if (["☑", "P", "N", "✖", "R"].includes(number_previous)) {
      number_previous = 0;
    }

    // TH thay đổi giá trị các index hiện tại (Nút 5 R)
    if (["R"].includes(return_arr[index])) {
      for (let g = index; g < return_arr.length; g++) {
        if (["☑", "P", "N", "✖"].includes(return_arr[g]) && g !== index) {
          continue;
        }
        number_previous += 1;
        return_arr[g] = number_previous;
      }
      return return_arr;
    }

    // TH thay đổi giá trị các index kế sau
    for (let g = index + 1; g < return_arr.length; g++) {
      if (["☑", "P", "N", "✖"].includes(return_arr[g])) {
        continue;
      }
      number_previous += 1;
      return_arr[g] = number_previous;
    }

    return return_arr;
  }

  const handleClickMatSau = (e, _index) => {
    // Nút T: "☑", Nút P: "P", Nút N: "N", Nút X: "✖"
    e.stopPropagation();

    const valueChangeInput = "☑";
    const updatedData = [...valueBase64];
    const arrayData = updatedData.map((item) => item.value_id);

    const return_data = updateArray(arrayData, _index, valueChangeInput);

    for (let i = 0; i < return_data.length; i++) {
      updatedData[i].value_id = return_data[i];
    }

    setValueBase64(updatedData);
  };

  const handleClickOther = (e, _index) => {
    e.stopPropagation();

    const valueChangeInput = "P";
    const updatedData = [...valueBase64];
    const arrayData = updatedData.map((item) => item.value_id);

    const return_data = updateArray(arrayData, _index, valueChangeInput);

    for (let i = 0; i < return_data.length; i++) {
      updatedData[i].value_id = return_data[i];
    }

    setValueBase64(updatedData);
  };

  const handleClickNameplate = (e, _index) => {
    e.stopPropagation();

    const valueChangeInput = "N";
    const updatedData = [...valueBase64];
    const arrayData = updatedData.map((item) => item.value_id);
    const return_data = updateArray(arrayData, _index, valueChangeInput);

    for (let i = 0; i < return_data.length; i++) {
      updatedData[i].value_id = return_data[i];
    }

    setValueBase64(updatedData);
  };

  const handleClickClearImage = (e, _index) => {
    e.stopPropagation();

    const valueChangeInput = "✖";
    const updatedData = [...valueBase64];
    const arrayData = updatedData.map((item) => item.value_id);

    const return_data = updateArray(arrayData, _index, valueChangeInput);

    for (let i = 0; i < return_data.length; i++) {
      updatedData[i].value_id = return_data[i];
    }

    setValueBase64(updatedData);
  };

  const handleClickReset = (e, _index) => {
    e.stopPropagation();
    const valueChangeInput = "R";
    const updatedData = [...valueBase64];
    const arrayData = updatedData.map((item) => item.value_id);

    const return_data = updateArray(arrayData, _index, valueChangeInput);

    for (let i = 0; i < return_data.length; i++) {
      updatedData[i].value_id = return_data[i];
    }
    setValueBase64(updatedData);
  };

  const KeyPressF1 = () => {
    document.getElementById("btn-submit").click();
  };

  useEffect(() => {
    fetchListPumb();
  }, []);

  useEffect(() => {
    if (listPumb.length === 1) {
      setValueListPumb(listPumb[0]);
      fetchDataInsert(listPumb[0].pumb_id);
    }
  }, [listPumb]);

  useEffect(() => {
    if (valueBase64.length !== 0) {
      const handleKeyPress = (event) => {
        if (event.key === "F1") {
          event.preventDefault();
          KeyPressF1();
        }
        if (event.key === "Enter") {
          event.preventDefault();
        }
      };

      document.addEventListener("keydown", handleKeyPress);

      return () => {
        document.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [valueBase64]);

  return (
    <>
      {loadingBtnSubmit && (
        <div className="loading-overlay">
          <Article key="bars">
            <ReactLoading type="bars" color="#fff" />
            <Prop>Đang phân loại dữ liệu...</Prop>
          </Article>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        {valueListPumb !== undefined && (
          <Select
            size={"middle"}
            id="code_city"
            style={{ width: "10%", height: "3vh" }}
            optionFilterProp="children"
            placeholder="Chọn mã máy"
            onChange={chooseModel}
            defaultValue={valueListPumb.pumb_model}
          >
            {listPumb.map((item, index) => (
              <Select.Option
                key={item.pumb_id}
                value={item.pumb_id}
                is_multi={item.is_multi}
                lv1_fields={item.lv1_fields}
                lv3_fields={item.lv3_fields}
                pumb_model={item.pumb_model}
              >
                {item.pumb_model}
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
      {valueBase64.length !== 0 ? (
        <>
          <div className="entry_classification_container-fluid">
            <div className="entry_classification_container">
              <Row gutter={16} className="entry_classification_row">
                {valueBase64.length !== 0 &&
                  valueBase64.map((item, _index) => (
                    <Col span={2} key={item.id}>
                      <Card
                        hoverable
                        className="entry_classification_card"
                        cover={
                          <img
                            alt="Red dot"
                            src={item.img_base64}
                            style={{ height: "12vh" }}
                            onClick={() => handleClickCard(_index)}
                          ></img>
                        }
                      >
                        <span
                          style={{
                            fontWeight: 600,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: 18,
                          }}
                        >
                          {item.value_id}
                        </span>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Col span={8} style={{ textAlign: "center" }}>
                            <Tooltip title="Mặt sau">
                              <IconButton
                                onClick={(e) => handleClickMatSau(e, _index)}
                              >
                                <span style={{ fontSize: 12 }}>☑</span>
                              </IconButton>
                            </Tooltip>
                          </Col>
                          <Col span={8} style={{ textAlign: "center" }}>
                            <Tooltip title="Other">
                              <IconButton
                                onClick={(e) => handleClickOther(e, _index)}
                              >
                                <span style={{ fontSize: 12 }}>P</span>
                              </IconButton>
                            </Tooltip>
                          </Col>

                          <Col span={8} style={{ textAlign: "center" }}>
                            <Tooltip title="Nameplate">
                              <IconButton
                                onClick={(e) => handleClickNameplate(e, _index)}
                              >
                                <span style={{ fontSize: 12 }}>N</span>
                              </IconButton>
                            </Tooltip>
                          </Col>
                          <Col span={2}></Col>
                          <Col span={10} style={{ textAlign: "center" }}>
                            <Tooltip title="Xóa ảnh">
                              <IconButton
                                onClick={(e) =>
                                  handleClickClearImage(e, _index)
                                }
                              >
                                <span style={{ fontSize: 12 }}>✖</span>
                              </IconButton>
                            </Tooltip>
                          </Col>
                          <Col span={10} style={{ textAlign: "center" }}>
                            <Tooltip title="Reset">
                              <IconButton
                                onClick={(e) => handleClickReset(e, _index)}
                              >
                                <AutorenewIcon style={{ fontSize: 14 }} />
                              </IconButton>
                            </Tooltip>
                          </Col>
                          <Col span={2}></Col>
                        </Row>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </div>
          </div>
          <div>
            <Row>
              <Col
                span={12}
                style={{
                  fontWeight: 600,
                  fontSize: 18,
                  margin: "auto",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <Col span={4}>☑: Mặt sau</Col>
                <Col span={4}>P: Other</Col>
                <Col span={4}>N: Nameplate</Col>
                <Col span={4}>✖: Xóa ảnh</Col>
                <Col span={4} style={{ display: "flex", alignItems: "center" }}>
                  <AutorenewIcon style={{ fontSize: 16 }} />: Reset
                </Col>
              </Col>
              <Col
                span={12}
                style={{
                  margin: "auto",
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 10,
                  paddingRight: 10,
                }}
              >
                <Button
                  id="btn-submit"
                  type="primary"
                  onClick={onFinish}
                  loading={loadingBtnSubmit}
                >
                  SUBMIT F1
                </Button>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <Col span={24}>
          <div className="container-noData-file-manager">
            <div style={{ display: "grid" }}>
              <img src={NoDataIcon} alt=""></img>
              <p>There is no data to display</p>
            </div>
          </div>
        </Col>
      )}

      <ShowModalDetailEntry
        isShowModalDetail={isShowModalDetail}
        setIsShowModalDetail={setIsShowModalDetail}
        valueSecondImgBase64={valueSecondImgBase64}
      />
    </>
  );
};

export default Entry_Classification;
