
import React, { useState } from 'react'
import FormRequestPassword from './FormRequestPassword'
import FormVerifyPassword from './FormVerifyPassword'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Layout } from 'antd';


const MySwal = withReactContent(Swal);
const ToastCameraNotFound = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  willClose: () => {
    window.location.href = "/";
  },
  customClass: {
    popup: "my-custom-popup",
    title: "custom-title-alert-camera",
    timerProgressBar: "my-custom-progress-bar-success", // Thêm class tùy chỉnh
  },
});

export default function ForgotPassword() {
  const [sendVerify, setSendVerify] = useState(false)
  const submitRequestLink = () => {
    // setSendVerify(true)

    ToastCameraNotFound.fire({
      icon: "warning",
      title: "Contact with Admin if You need Reset Password",
    });
  }

  return (
    <Layout className='layoutForgotPassword' style={{ height: '100svh', maxHeight: '100svh' }}>
      {sendVerify === false ? <FormRequestPassword submitRequestLink={submitRequestLink} /> : <FormVerifyPassword />}
    </Layout>
  )
}

