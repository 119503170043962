import { Button, Col, Input, Row, Select } from "antd";
import ClearDataSearch from "../Button/ClearDataSearch";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import PropTypes from "prop-types";

const SearchDashboard = ({
  handleClearDataSearch,
  checkValueStatus,
  handleChangeStatus,
  checkValueResult,
  handleChangeResult,
  checkValueSearch,
  handleSearchTableManagemant,
  handleClickSearch,
}) => {
  return (
    <Row style={{ width: "100%" }}>
      <Col span={5}></Col>
      <Col span={1}>
        <ClearDataSearch handleClearDataSearch={handleClearDataSearch} />
      </Col>
      <Col span={4} style={{ margin: "auto" }}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Status"
          value={checkValueStatus}
          onChange={handleChangeStatus}
          allowClear
          options={[
            {
              value: "Processing",
              label: "Processing",
            },
            {
              value: "Complete",
              label: "Complete",
            },
            {
              value: "Verified",
              label: "Verified",
            },
          ]}
        />
      </Col>
      <Col span={4} offset={1} style={{ margin: "auto" }}>
        <Select
          style={{
            width: "100%",
          }}
          value={checkValueResult}
          placeholder="Result"
          onChange={handleChangeResult}
          allowClear
          options={[
            {
              value: "Processing",
              label: "Processing",
            },
            {
              value: "Qualified",
              label: "Qualified",
            },
            {
              value: "Not Qualified",
              label: "Not Qualified",
            },
            {
              value: "Image Not Good",
              label: "Image Not Good",
            },
          ]}
        />
      </Col>
      <Col span={5} offset={1} style={{ margin: "auto" }}>
        <Input
          placeholder="Search MFG No, Model Name"
          suffix={<SearchOutlined />}
          value={checkValueSearch}
          onChange={handleSearchTableManagemant}
          className="management-dashboard-input-search"
        />
      </Col>
      <Col span={2} offset={1} style={{ margin: "auto" }}>
        <Button variant="outlined" onClick={handleClickSearch}>
          Search
        </Button>
      </Col>
    </Row>
  );
};

SearchDashboard.propTypes = {
  handleClearDataSearch: PropTypes.func,
  checkValueStatus: PropTypes.any,
  handleChangeStatus: PropTypes.func,
  checkValueResult: PropTypes.any,
  handleChangeResult: PropTypes.func,
  checkValueSearch: PropTypes.any,
  handleSearchTableManagemant: PropTypes.func,
  handleClickSearch: PropTypes.func,
};

export default SearchDashboard;
