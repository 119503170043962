import * as React from "react";
import { ColorModeContext, useMode } from "../../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "../Layout.css";
import HeaderAdmin from "./HeaderAdmin";
import PropTypes from "prop-types";

const CustomLayoutAdminFC = ({ children }) => {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app" style={{ display: "flex", height: "100vh" }}>
          <main className="content1">
            <HeaderAdmin />
            <div className="container-fluid" style={{ maxWidth: "100%" }}>
              {children}
            </div>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export const CustomLayoutAdmin = ({ isLogged, children }) => {
  return isLogged ? (
    <CustomLayoutAdminFC children={children} />
  ) : (
    <CustomLayoutAdminFC children={children} />
  );
};

CustomLayoutAdminFC.propTypes = {
  children: PropTypes.any,
};

CustomLayoutAdmin.propTypes = {
  children: PropTypes.any,
  isLogged: PropTypes.any,
};
