export const dataLK = {
  message: "Success!",
  op_id: "218",
  pack_id: 10,
  pack_table: "2405_PACK",
  op_table: "2405_OP",
  prioriti: 1,
  image_exits: 0,
  Input: [
    {
      field_name: "Motor power",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Motor power, isulated type",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Single phase/ three phase",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Motor isulated typer ",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Model",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Motor Voltage",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Frequency",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Motor current intensity value",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "Motor rotation quantity",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
    {
      field_name: "SERIAL No.",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
      hotkeys: "",
    },
  ],
  Grid: [
    {
      field_name: "Motor rotation quantity",
      sheet_name: "Tem",
      check_result: "",
      result: "",
      mark: "",
    },
  ],
  path_files: [
    [
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424143953680.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144012177.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144131153.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144132339.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144213789.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144847178.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144903325.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144907141.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144912815.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144243253.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144924676.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144928836.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144938350.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144952212.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144144310.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144158263.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144305203.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144843621.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144253101.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144309622.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144327787.png",
      "/Files/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144336470.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145001348.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145002371.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145441404.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_03042414560718.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145023969.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145526749.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145545748.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145522246.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145424492.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145537324.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145534661.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145014968.png",
      "/Files/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145512407.png",
    ],
  ],
  path_thumbs: [
    [
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424143953680.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144012177.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144131153.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144132339.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144213789.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144847178.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144903325.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144907141.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144912815.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144243253.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144924676.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144928836.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144938350.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144952212.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144144310.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144158263.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144305203.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424144843621.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144253101.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144309622.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144327787.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_124611319410_LK-1_1_ImageCapture_030424144336470.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145001348.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145002371.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145441404.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_03042414560718.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145023969.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145526749.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145545748.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145522246.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145424492.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145537324.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145534661.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125206424595_LK-1_1_ImageCapture_030424145014968.png",
      "/Thumbnail/2405/240503_143722478095_LK_1_240403_125733463556_LK-1_1_ImageCapture_030424145512407.png",
    ],
  ],
};

export const dataShortcut = [
  {
    id: "1",
    shortcut: "F1",
    shortcutName: "Submit phiếu",
  },
  {
    id: "2",
    shortcut: "F2",
    shortcutName: "Hiển thị form Q&A",
  },
  {
    id: "3",
    shortcut: "F3",
    shortcutName: "Hiển thị form Shortcut",
  },
  {
    id: "4",
    shortcut: "Ctrl + ArrowDown",
    shortcutName: "Bấm xuống input trong table",
  },
  {
    id: "5",
    shortcut: "Ctrl + ArrowRight",
    shortcutName: "Bấm qua phải input trong table",
  },
  {
    id: "6",
    shortcut: "Ctrl + ArrowLeft",
    shortcutName: "Bấm qua trái input trong table",
  },
  {
    id: "7",
    shortcut: "Ctrl + ArrowUp",
    shortcutName: "Bấm lên input trong table",
  },
  {
    id: "8",
    shortcut: "Ctrl + 0",
    shortcutName: "Trả zoom về vị trí ban đầu",
  },
  {
    id: "9",
    shortcut: "Ctrl + 1",
    shortcutName: "Zoom góc trên bên trái",
  },
  {
    id: "10",
    shortcut: "Ctrl + 2",
    shortcutName: "Zoom góc trên bên phải",
  },
  {
    id: "11",
    shortcut: "Ctrl + 3",
    shortcutName: "Zoom dưới trên bên trái",
  },
  {
    id: "12",
    shortcut: "Ctrl + 4",
    shortcutName: "Zoom dưới trên bên phải",
  },
  {
    id: "13",
    shortcut: "Alt + 1",
    shortcutName: "☑",
  },
  {
    id: "14",
    shortcut: "Alt + 2",
    shortcutName: "✔",
  },
  {
    id: "15",
    shortcut: "Alt + 3",
    shortcutName: "✖",
  },
  {
    id: "16",
    shortcut: "Alt + 4",
    shortcutName: "―",
  },
  {
    id: "17",
    shortcut: "Alt + 5",
    shortcutName: "Φ",
  },
  {
    id: "18",
    shortcut: "Nhập 1",
    shortcutName: "原票がはっきり見えない",
  },
  {
    id: "19",
    shortcut: "Nhập 2",
    shortcutName: "※特注要素・備考: ご自身でご確認ください。",
  },
];
